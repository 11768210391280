export default {
  blogerName: 'MAZIK',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@Mazik37/streams',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@Tazikmaz',
    },
    {
      name: 'telegram',
      url: 'https://t.me/mazik999',
    },
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/mazikalmazik/videos',
    }
  ],
  projects: [
    {
      name: 'monro',
      url: 'https://mnr-irrs01.com/cf416191a',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs01.com/c8ece44d7',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs12.com/c9e0e8a72',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/c2c61dfef',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-sfgjhyrt02.com/c520d124d',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://iz-ir43.com/cfb27dd2e',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/c80329df5',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsopard.com/c705ecd3e',
      gameTitle: 'Candy Monsta (BGaming)',
    },
    {
      name: 'rox',
      url: 'https://rox-fwuocypyjf.com/51165f3f',
      gameTitle: 'Mechanical Clover (BGaming)',
    },
    {
      name: 'volna',
      url: 'https://vln-irrs01.com/cebd2636c',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>MAZIK</strong> и получи 125 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'MAZIK',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>125FS</strong><div>За регистрацию по промокоду</div>',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
